import React from 'react';

import { Icon, CloseSVG } from '@newsela/angelou';

import { $header, $headerTop, $headerTitle, $closeButton, $tabContainer, getTabStyle } from './style';
import { TABS } from '../StandardsSelector';

const Header = ({ activeTab, setActiveTab, handleClose }) => {
  const handleTabChange = (tab) => setActiveTab(tab);

  return (
    <header css={$header}>
      <div css={$headerTop}>
        <span css={$headerTitle}>Standards</span>
        <div onClick={handleClose} css={$closeButton}>
          <Icon SvgComponent={CloseSVG} />
        </div>
      </div>
      <div css={$tabContainer}>
        <span css={getTabStyle(activeTab, TABS.SEARCH)} onClick={() => handleTabChange(TABS.SEARCH)}>
          Recommendations & Search
        </span>
        <span css={getTabStyle(activeTab, TABS.REVIEW)} onClick={() => handleTabChange(TABS.REVIEW)}>
          Review & Apply
        </span>
      </div>
    </header>
  );
};

export default Header;
