import { has } from 'lodash-es';

import AlgorithmParameters from './AlgorithmParameters';
import AppButton from './AppButton';
import ArticleLevels from './ArticleLevels';
import ArticleLevelsSelector from './ArticleLevelsSelector';
import ArticleLoader from './ArticleLoader';
import AssessmentLevels from './AssessmentLevels';
import CheckboxInput from './Checkbox';
import CheckboxGroupInput from './CheckboxGroup';
import Cloudinary from './Cloudinary';
import DatePicker from './DatePicker';
import Duration from './Duration';
import Editor from './Editor';
import EditorList from './EditorList';
import File from './File';
import FocalPoint from './FocalPoint';
import GradeBandLevelSelect from './GradeBandLevelSelect';
import Level from './Level';
import MultilingualText from './MultilingualText';
import MultipleChoice from './MultipleChoice';
import PowerWordList from './PowerWordList';
import ProsemirrorLeveler from './ProsemirrorLeveler';
import Radio from './Radio';
import RevisionHistory from './RevisionHistory';
import Select from './Select';
import Slider from './Slider';
import StandardSelect from './StandardSelect';
import StandardsSelector from './StandardsSelector';
import Static from './Static';
import Text from './Text';
import Textarea from './Textarea';

// These are the types of inputs we support in form fields. Non-input components
// that are primarily used in forms (e.g. AttachedButton) live in the
// components/ folder. These other components are not instantiated as fields
// directly, but rather are used by other inputs and by form containers.

// If a field schema doesn't map to one of these inputs, it will not
// be rendered in the form.
const supportedInputs = {
  // Alphabetical, kebab-case
  'app-button': AppButton,
  'algorithm-parameters': AlgorithmParameters,
  'article-levels': ArticleLevels,
  'article-levels-selector': ArticleLevelsSelector,
  'article-loader': ArticleLoader,
  'assessment-levels': AssessmentLevels,
  checkbox: CheckboxInput,
  'checkbox-group': CheckboxGroupInput,
  cloudinary: Cloudinary,
  datepicker: DatePicker,
  duration: Duration,
  editor: Editor,
  'editor-list': EditorList,
  file: File,
  'focal-point': FocalPoint,
  'grade-band-level-select': GradeBandLevelSelect,
  level: Level,
  'multiple-choice': MultipleChoice,
  'multilingual-text': MultilingualText,
  'power-word-list': PowerWordList,
  prosemirror: ProsemirrorLeveler,
  radio: Radio,
  'revision-history': RevisionHistory,
  select: Select,
  'standard-select': StandardSelect,
  'new-standard-selector': StandardsSelector,
  static: Static,
  slider: Slider,
  text: Text,
  textarea: Textarea
};

export default supportedInputs;

export function isSupportedInput (input) {
  return has(supportedInputs, input);
}
