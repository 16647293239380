import { createContext, useContext, useEffect, useState } from 'react';

import { fetchJSON } from '@client/utils/rest';
import * as toast from '@client/utils/toast';

import { EXPERIMENT_STATES } from './experiments';

const ExperimentContext = createContext(null);

export const ExperimentProvider = ({ children }) => {
  const [experiments, setExperiments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExperiments = async () => {
      try {
        const response = await fetchJSON(`${process.env.MONOLITH_BASE_URL}/api/v3/user/me/experiment/`, {
          credentials: 'include',
        });
        setExperiments(response);
      } catch (err) {
        setError(err.message);
        // Displays a toast for more visibility, should rarely fail as this is a crucial endpoint.
        // Errors are also sent to Datadog, so we avoid blocking the user entirely by not throwing here.
        toast.error('Could not load user experiments');
      } finally {
        setLoading(false);
      }
    };

    fetchExperiments();
  }, []);

  return (
    <ExperimentContext.Provider value={{ experiments, loading, error }}>
      {children}
    </ExperimentContext.Provider>
  );
};

/**
 * Provides access to experiment data within the `ExperimentProvider` context.
 *
 * If you only need to check if a specific experiment is active, consider using `useIsExperimentActive` instead.
 *
 * @throws {Error} If used outside of an `ExperimentProvider`.
 * @returns {{ experiments: Record<string, string>, loading: boolean }} Experiment data and loading state.
 */
export const useExperiment = () => {
  const context = useContext(ExperimentContext);
  if (!context) {
    throw new Error('useExperiment must be used within an ExperimentProvider');
  }
  return context;
};

/**
 * Checks if a specific experiment is active.
 *
 * **Important:** If the data is not readily available, you must check for `loading` before using the result to avoid false negatives
 * while experiments are still being fetched.
 *
 * @param {string} experiment - The experiment key to check.
 * @returns {boolean | undefined} - Returns `true` if the experiment is on, `false` if off, and `undefined` while loading or if there is none.
 */
export const useIsExperimentActive = (experiment) => {
  const { experiments, loading } = useExperiment();
  if (loading || !experiments) return undefined;
  return experiments[experiment] === EXPERIMENT_STATES.on;
};
