import { css } from '@emotion/core';
import { typographyStyles, constants } from '@newsela/angelou';

const { heading4Obj, heading3Obj, fontFamily } = typographyStyles;
const uiColors = constants.colors.ui;

export const $header = css`
  height: 98px;
  margin: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const $headerTop = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

export const $headerTitle = css`
  ${heading3Obj}
  font-family: ${fontFamily.noto};
  font-size: 20px;
`;

export const $closeButton = css`
  cursor: pointer;
`;

export const $tabContainer = css`
  display: flex;
`;

export const getTabStyle = (activeTab, tab) => css`
  cursor: pointer;
  ${heading4Obj}
  padding-bottom: 2px;
  color: ${activeTab !== tab ? uiColors.grey[300] : '#424147'};
  ${activeTab === tab ? `border-bottom: solid 3px ${uiColors.coreBlue[500]};` : ''}
  ${tab === 'search' ? 'margin-right: 32px;' : ''}
`;
