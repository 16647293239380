import { css } from '@emotion/core';

export const $modalContentStyles = css`
  background-color: white;
  height: 88vh;
  margin: 0 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const $modalBody = css`
overflow-y: scroll; 
flex: 1;
`;
