import React from 'react';

import StandardsList from '../StandardsList';
import { TABS } from '../StandardsSelector';

const ReviewContainer = () => {
  return (
    <div>
      <StandardsList activeTab={TABS.REVIEW} />
    </div>
  );
};

export default ReviewContainer;
