import React from 'react';

import { $filtersContainer } from './style';

const Filters = () => {
  return (
    <div css={$filtersContainer}>
      <input />
    </div>
  );
};

export default Filters;
