import React from 'react';

import { $standardsContainer } from './styles';

const StandardsList = () => {
  return (
    <div css={$standardsContainer}>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis, perferendis? Fugit iste nam illum recusandae earum quam pariatur voluptates, vero nemo quibusdam et provident mollitia. Laborum fuga dolor aut officiis?
      Cum atque explicabo omnis voluptate, amet totam numquam nam? Sit culpa laudantium sint, velit earum nobis assumenda at nostrum iure, nihil corporis quod molestiae dolor unde dignissimos et quidem? Cupiditate.
      Mollitia sunt possimus minus. Perferendis velit atque maiores incidunt unde, ipsam rerum in sequi, molestiae nisi vero quam dolorem voluptatum, placeat asperiores ipsum repudiandae hic voluptates et earum. Ut, hic?
      Voluptates distinctio ratione tenetur, maxime magnam aliquam provident atque cupiditate temporibus debitis at assumenda ea maiores ducimus eius earum necessitatibus magni laudantium nam quisquam quasi iure totam nihil! Itaque, totam?
      Alias quidem, debitis temporibus nostrum voluptates itaque. Architecto eius, vel praesentium soluta nam officiis eos eligendi error quis inventore magni consectetur iste modi, minima, voluptates aut atque debitis earum sed!
      Tempore eius cupiditate perspiciatis quis iste corrupti quia inventore laudantium aspernatur aliquam vitae quo, sed mollitia porro, assumenda asperiores minima optio similique! Atque voluptatum quis pariatur vel minima quod commodi!
      Esse dignissimos similique ipsam ullam, laboriosam architecto labore quam hic deserunt cupiditate natus ut minima soluta vitae aut. Recusandae nihil blanditiis cupiditate tempore pariatur itaque aspernatur ipsum neque accusamus porro!
      Iure possimus non similique distinctio omnis vel, sed assumenda beatae nesciunt aperiam dignissimos architecto sapiente aliquid ipsa voluptatum saepe dolorum totam ipsam eius. Libero nihil dolorum unde esse dignissimos numquam?
      Neque praesentium rerum consequatur minus a maiores nesciunt repudiandae unde, incidunt eos optio quis provident laborum nam odit, adipisci atque saepe molestiae tempora iste asperiores veritatis eius! Molestiae, quia earum.
      Voluptates, praesentium iusto pariatur dolorum esse amet at quas voluptatibus qui quibusdam cupiditate aut optio. Voluptate adipisci eaque possimus corrupti neque ea error similique quae! Minima explicabo quis dignissimos maxime?
      Illo at ex commodi est, omnis distinctio, iure itaque ullam laudantium blanditiis dicta ipsum quae tempora in ut vitae laborum earum. Quod, in. Suscipit quas commodi, porro cum magnam praesentium?
      Molestias, eius veritatis obcaecati quidem repellendus natus enim voluptate maiores ducimus magni excepturi doloribus. Iure vel aliquam laborum consectetur numquam. Neque perspiciatis sapiente debitis! Voluptatem, sed id? Repellat, quam ipsum!
      Vitae, cum placeat, perferendis odio nam perspiciatis harum debitis voluptatibus exercitationem repudiandae mollitia sunt reprehenderit quia culpa, voluptate provident ipsum et iste velit soluta quos recusandae sit. Obcaecati, ipsam asperiores?
      Soluta incidunt est, eius excepturi impedit quae. Harum fugit quaerat fugiat, odit similique culpa numquam deserunt voluptas fuga. Enim quod maxime fuga nesciunt, saepe voluptas quae expedita odio sapiente odit!
      Molestias, cumque! Fugiat inventore dignissimos laborum voluptatem quasi at assumenda facere officiis. Saepe temporibus, dolorem reprehenderit totam illo blanditiis doloribus eius nihil qui vel. Sunt tempore tenetur sapiente fugit nostrum?
      Eveniet dolor velit error magnam alias ipsam totam ipsa rem quae sunt sint quia, temporibus quaerat tempora optio eaque dolores dicta tenetur? Hic aspernatur, recusandae molestiae commodi dolorem voluptates ducimus.
      Facilis ut, iure numquam aperiam, similique nesciunt delectus maiores, nam harum voluptatum provident labore totam? Earum, aspernatur veniam sit repellat ullam tempora dolor, qui minus soluta quisquam eum eaque similique.
      Ipsam eum consectetur fuga officia, saepe pariatur blanditiis, sit placeat cumque optio, deleniti molestiae id quibusdam accusantium facilis molestias quam. Optio quos cumque itaque et, beatae doloremque animi excepturi suscipit?
      Delectus nisi incidunt iusto blanditiis beatae in, est natus dolorum quo? Qui rerum numquam repudiandae, dolor laborum sequi sunt. Architecto, odio? In magnam corporis, iure ea delectus quidem soluta ex.
      Minima perspiciatis necessitatibus modi, iste eos dicta vero cumque itaque, nam fuga dolor? Totam culpa quos officiis reprehenderit libero neque ad qui aspernatur magnam, ex beatae aperiam vitae odio repudiandae.
      Nostrum maiores iure facere ipsam atque, sed cumque quam laboriosam esse mollitia reiciendis expedita officiis suscipit culpa. Est commodi vitae veritatis fugit, doloremque, aperiam, harum eligendi consequatur maiores laudantium dolore?
      Nisi ipsa nihil numquam sapiente mollitia molestiae impedit laboriosam voluptates iusto est nulla ipsam, ratione exercitationem repellendus nesciunt? Laudantium dolor, neque ipsum quidem odit omnis eum autem possimus vel ipsam.
      Magni provident fugiat dolore cum, sunt harum iste distinctio, similique esse eaque vitae eos adipisci alias voluptates quos a voluptas. Itaque repellendus ullam quos molestias necessitatibus, est sed alias dignissimos.
      Nobis, officia accusantium? Nobis non consequatur reiciendis. Quidem ipsam reiciendis qui, et maiores incidunt sed voluptate expedita cupiditate id facere nobis rem, ullam explicabo? Culpa ipsam neque consequatur sit omnis.
      Eligendi pariatur repellat natus suscipit facilis dicta optio ea, deserunt deleniti quasi, molestias accusamus laborum. Officia iste quaerat dignissimos earum, perferendis saepe, sit beatae harum aliquid necessitatibus nostrum quis blanditiis?
      Labore voluptas doloribus velit. Fugit at, tempore suscipit ipsum impedit vel et aspernatur debitis ad cumque enim pariatur ut animi nisi alias veritatis omnis a minima inventore eos explicabo quia?
      Aut, ut amet. Tempore dignissimos nostrum blanditiis laudantium veritatis assumenda fugit aspernatur quas numquam voluptates dolores error neque beatae aut a vitae, ab vero odit? Rerum sint maiores aliquam animi.
      Nostrum pariatur ad voluptatum qui odio, saepe, impedit ullam aliquid fuga animi molestiae. Quam omnis debitis exercitationem impedit id repellat distinctio nostrum iste, est tenetur ut unde laudantium velit expedita.
      Ducimus blanditiis optio ullam maiores voluptate fuga voluptatibus molestias eum, culpa mollitia pariatur nemo sunt nesciunt dolore quidem, nobis maxime minus laborum temporibus quibusdam? Accusantium quos error ex libero incidunt?
      Sequi accusamus illo eum, molestiae soluta possimus, quia id porro iusto placeat ea odit inventore quam voluptatum libero sit laudantium facilis natus labore fuga quae, non iste. Laborum, perspiciatis consequatur.
      Nostrum ea dolor dolores quisquam officiis autem aspernatur nam sit et, eligendi inventore dicta, ab quos error. Facere minima, voluptate dolorum ea sunt corrupti reiciendis quas cumque, quibusdam fugit incidunt!
      Dolorem, corporis accusantium autem iste enim dolore magnam numquam dolores incidunt sapiente, sit nostrum reiciendis dicta architecto sed nihil ab ullam! Eveniet ex recusandae laudantium provident corporis modi, dolores ducimus!
      Voluptas iusto illo expedita, repellendus facere minima architecto accusantium aspernatur, soluta consectetur laudantium deserunt sunt deleniti pariatur. Eius fuga dolores quidem autem, distinctio nesciunt? Dolores necessitatibus ea nostrum recusandae fugiat!
      Magni, quisquam asperiores veritatis temporibus eveniet aliquid, ut obcaecati omnis maiores reiciendis inventore consequuntur, iure labore assumenda culpa quo. Dignissimos autem ducimus nisi deserunt veniam labore beatae, sapiente reiciendis quo.
      Alias, officia soluta! Facilis, ipsum ullam. Possimus asperiores aliquam ullam quia velit alias assumenda, perferendis suscipit, repellendus commodi ipsa quis vitae delectus excepturi ipsam mollitia quibusdam eos facilis beatae explicabo.
      Asperiores minus, itaque nesciunt repudiandae odit dicta accusamus porro eligendi in fugit eum accusantium neque molestias necessitatibus. Quod numquam sapiente pariatur alias dolor, sed itaque recusandae quam velit, at dignissimos.
      Quae incidunt, laborum aliquam eius nobis voluptate nihil possimus ducimus rerum ipsam dolore ea sed quisquam cumque, harum at recusandae accusantium vitae sit molestias sequi a? Reprehenderit quam adipisci dolores.
      Amet perspiciatis quidem labore odit! Temporibus nemo vitae recusandae tempore, neque qui autem quas corrupti placeat in vero obcaecati hic numquam! Distinctio, sunt! Eligendi dolorum atque aliquid tempore? Distinctio, cupiditate?
      Perferendis quidem quaerat ipsa, repudiandae dolorem iusto illum soluta exercitationem odio voluptatum vero tempore nisi ad distinctio recusandae aspernatur quibusdam quia autem beatae accusantium. Commodi nisi perferendis eaque illo natus.
      Autem nam illo consequatur reprehenderit magni veniam culpa tempore at debitis illum expedita quis magnam ducimus totam voluptas porro deleniti cupiditate, ipsum exercitationem unde consequuntur. Est vero molestias incidunt ea.
      Ratione quaerat temporibus quam odit explicabo corporis tenetur saepe et magnam rem hic similique perspiciatis placeat, libero doloremque quisquam voluptate sit cupiditate fugit quidem nihil. Doloribus, suscipit eos. Nulla, asperiores.
      Optio consequatur assumenda molestias unde esse incidunt quis doloribus natus, fuga ut vel illo perferendis, rerum minima aliquid perspiciatis quaerat culpa quod facere atque maxime dolores dolorem odit nisi! Recusandae.
      Illum sequi placeat quod consequatur, asperiores non. Doloribus, recusandae perspiciatis blanditiis aliquam vel odit itaque sunt sapiente tempore sequi id magnam quidem est at qui! Deserunt ipsam omnis ipsa a.
      Ex accusantium obcaecati, pariatur repellat, dolore nesciunt laboriosam, eius exercitationem sunt perferendis quibusdam reprehenderit omnis repudiandae nostrum at fugiat asperiores iste voluptatum voluptatibus. Omnis eligendi obcaecati delectus incidunt dolores nemo.
      Sint velit error laborum eos atque totam, cum esse maiores, quos quia laboriosam. Nobis, molestias expedita. Quod sed hic molestiae obcaecati blanditiis impedit, eveniet est distinctio eligendi recusandae voluptates magni!
      Impedit distinctio enim neque. Illo ex minima officia modi, officiis quibusdam neque asperiores deleniti vero possimus tempora ea quam, tempore, enim eum veritatis quas? Beatae error quam magni unde sint!
      Quidem, possimus. Reiciendis vel rerum necessitatibus provident corrupti adipisci obcaecati veritatis quasi iste laudantium amet officia excepturi deleniti aliquam dolorem dicta, dolorum commodi repellendus, veniam aspernatur fuga quidem modi non!
      Reprehenderit animi fugiat dolorem quaerat doloremque ratione voluptatum quia adipisci dolor illo, eum aspernatur consequatur est aut soluta! Voluptatum animi blanditiis ut tempora, eum molestiae laudantium minus aliquid non rem.
      Laudantium repellat fugiat eveniet excepturi dignissimos possimus. Beatae, labore! Ipsam sapiente ea corrupti doloribus. Perferendis ab vero vitae sapiente ratione deserunt modi repellat sit fuga voluptatem. Exercitationem, ipsum deserunt! Velit.
      Commodi neque minus expedita perspiciatis in cum, tempore hic ducimus necessitatibus, deserunt veniam eum sequi enim fugiat quo exercitationem dolore pariatur! Voluptatibus ratione repellat aperiam distinctio, voluptates quidem praesentium quasi.
      Ut iure voluptates nisi magni id, in, labore nam est explicabo iste laborum beatae earum expedita maxime consequatur quae ad commodi nobis. Optio, suscipit deserunt tenetur aliquam sequi dolores nulla.
      Quos delectus fugiat quis quasi deserunt assumenda asperiores laboriosam dicta, rem odit, optio, culpa alias omnis accusantium nemo sunt quaerat nobis. Ipsum rem perspiciatis accusantium ad aspernatur consequuntur repellat optio.
      Unde assumenda fuga veniam illo maiores aspernatur! Inventore doloribus voluptate facilis quos molestiae, ex sequi. Fuga tempora delectus, dolorum velit, corporis assumenda alias voluptatum exercitationem dicta id soluta placeat veritatis.
      Nulla pariatur, maxime deserunt incidunt impedit aperiam quasi accusantium harum veniam cumque eius dolorem quis neque nobis in repellat necessitatibus dignissimos. Porro beatae ad architecto nihil voluptatibus minima itaque ipsum.
      In dolore, labore modi ea aut consequatur id numquam obcaecati! Dolorem deleniti rerum debitis dignissimos ratione, illum ullam non quasi quis voluptatibus beatae! Fuga natus officia, inventore assumenda quidem facere!
      Vel, expedita incidunt dolor at iste fugit. Quos odit omnis praesentium est incidunt nulla, nihil deleniti odio doloremque enim reiciendis eum ipsa soluta maxime dolor voluptatibus fuga blanditiis cum distinctio.
      Ratione, reiciendis! Ipsam iure labore atque quia! Maxime dolor rerum, libero aliquam ab nulla dolorem corrupti! Harum nobis debitis laboriosam culpa eveniet voluptate nam! Illo esse architecto dolorum temporibus cum.
      Iusto officiis nisi repellat deserunt voluptas, obcaecati quasi inventore hic quam, quae soluta molestias nam quisquam quidem aut quas numquam praesentium ratione! Nostrum accusamus debitis iusto quos autem, natus quibusdam?
      Iste libero itaque, animi alias at consequatur. Iste fugiat autem soluta harum voluptas mollitia nobis eum necessitatibus rem velit aliquam, cum quos distinctio, facilis ratione sit earum eius! Quae, earum!
      Corporis laudantium aliquam quibusdam dolorum incidunt accusamus ad sapiente voluptate magni. Id praesentium inventore ipsum! Hic alias vel laborum harum quae! Nesciunt aut reprehenderit laudantium soluta praesentium cupiditate laboriosam tempore?
      Distinctio voluptatibus iure nisi vel, tempora architecto iste! Asperiores numquam fugiat qui quaerat dignissimos eum deserunt repellat suscipit ullam inventore, doloribus id porro? Expedita aliquid dolore nobis consequatur! Hic, recusandae!
      Aperiam ad quia veritatis deleniti, laboriosam maiores culpa. Nesciunt accusamus aspernatur commodi veniam ut eligendi pariatur dignissimos earum consectetur qui necessitatibus nostrum debitis non, eveniet, dolorem quia reiciendis eius fuga!
      Enim alias voluptate magnam expedita, delectus dolores doloribus harum libero deserunt, sint corrupti, quidem sequi. Nihil odio sapiente mollitia ratione accusamus dolorem vero veritatis dolores necessitatibus vitae, voluptatibus consectetur possimus!
      Et quasi vel quia, hic provident nesciunt. Illo soluta explicabo tenetur nihil cum expedita unde quae aperiam praesentium esse odio suscipit, fugit error hic sit saepe eaque repudiandae molestiae rem.
      Natus debitis est placeat itaque ea ullam architecto nulla repellendus beatae odit soluta quia odio maiores mollitia, officiis alias, ipsam ex doloribus. Commodi iste optio impedit nobis provident asperiores fugit.
      Suscipit nesciunt doloribus exercitationem neque eligendi? Numquam architecto labore itaque nemo nulla harum officiis, atque alias ullam a, molestias ab, corrupti provident veniam unde sunt libero quod eos eum maxime.
      Aliquam magni iure, similique et ipsam nesciunt deserunt reprehenderit cum vitae harum tempora labore dicta ea architecto repellendus repellat fuga! Perspiciatis repudiandae, veritatis laudantium consectetur maxime officiis odit exercitationem mollitia.
      Labore nam veritatis, facere, numquam omnis atque molestias, eos quis suscipit adipisci enim voluptate doloremque reiciendis. Tempore necessitatibus saepe expedita! Natus laboriosam, voluptatum dolore facilis ducimus modi libero magni ipsa?
      Blanditiis, vel. Quod repellendus temporibus, impedit assumenda fuga tempora placeat molestiae rerum perferendis dolore, est ipsam officia quia ex recusandae deserunt asperiores. Optio asperiores doloribus, reprehenderit labore amet est nesciunt.
      Pariatur nihil, impedit ad voluptates quaerat ea inventore maiores. Ut, quam culpa? Provident doloribus ullam recusandae, perspiciatis fuga obcaecati eveniet eum sed dignissimos iste assumenda possimus dicta illum mollitia ipsum.
      Voluptate ullam cum accusantium natus, maiores provident a facilis, nulla tempora illum amet aut perferendis sapiente asperiores fugit error, ratione vitae explicabo veritatis aperiam nobis vero commodi repellendus mollitia. Corrupti.
      Obcaecati et magnam labore autem doloribus, adipisci exercitationem amet expedita perspiciatis corrupti mollitia quaerat, deserunt laudantium quasi veniam quibusdam quidem quisquam voluptates aliquam harum deleniti. Vel molestiae odio et animi.
      Rem molestias ipsam voluptas, perspiciatis praesentium commodi molestiae culpa delectus, alias suscipit porro architecto quam, veritatis sit dolor deserunt quas aliquid dolorum. Mollitia ad ullam, explicabo placeat aspernatur eum iure!
      Enim debitis facilis, corporis amet tenetur vel sequi fuga magnam velit sapiente nulla non excepturi? Dolore fugit vel voluptatibus, eveniet et, ipsam architecto tempore quasi cupiditate dolores at odio quisquam!
      Quae harum ipsam explicabo omnis optio? Dolore possimus labore voluptatum culpa quis et exercitationem recusandae, magni delectus molestias repudiandae officiis est impedit omnis provident! Sapiente fuga error aliquid eos cum.
      Expedita labore odio, cum nostrum sequi reprehenderit voluptate, nobis velit dolorum natus ex itaque quae facilis quod deleniti non est quaerat amet debitis. Laudantium rem qui alias. Repellendus, fugiat nemo!
      Ratione perferendis cum exercitationem sit aliquid eligendi consectetur nihil iure animi labore odio sequi, maxime id. Ratione, quaerat laborum architecto autem sequi nam quam atque nemo laboriosam dolores, vel eaque?
      Assumenda aut repellat sed corporis ipsa ducimus non, illum repudiandae nulla dolor accusantium fuga perspiciatis aperiam iusto quos suscipit expedita eum consequuntur inventore natus reprehenderit deleniti neque iure. Culpa, repellat.
      Excepturi adipisci cumque provident magnam neque, accusamus, ea repellat consectetur nemo vero deserunt ducimus aliquam voluptatem, obcaecati cum. Omnis ab officia ratione? Ducimus perspiciatis assumenda aut, facere est similique aperiam.
      Perspiciatis error eveniet, beatae perferendis aliquam dolor sint obcaecati quis voluptas aut non in cum facere ea! Eligendi consequatur molestiae aperiam consectetur veritatis quidem, illo nobis esse, debitis tempore nulla.
      Porro cumque impedit, suscipit saepe nobis fugit quasi ratione quae atque sapiente! Deserunt quae aliquam sapiente molestiae saepe odio corporis porro quo, dignissimos culpa explicabo hic. Libero culpa provident unde.
      Eos sequi neque vitae consequuntur error beatae inventore tempore dolores velit autem suscipit dignissimos maiores non cum facere temporibus quis saepe, mollitia reiciendis ab consequatur minima? Quidem est aliquid magnam.
      Fugit sint et sapiente vero, praesentium nobis? Consectetur numquam officia nostrum ratione eaque obcaecati illo corporis id labore nulla reprehenderit, laudantium soluta. Accusantium, ut sed a est vel fugiat soluta?
      Quisquam iste neque a nulla explicabo? Distinctio nihil temporibus earum et iste totam rem doloribus quae, debitis quas est optio, eos cumque autem numquam iusto accusamus eveniet. Facilis, maxime fugiat?
      Earum enim voluptates quam totam dolore sint perferendis animi quibusdam harum iusto, voluptatibus aspernatur temporibus debitis exercitationem illo modi quisquam dignissimos maxime aliquam asperiores? Enim provident quo minima magni rem?
      Praesentium asperiores nihil temporibus a ratione facilis quod eveniet, dolore esse hic culpa, consequatur maxime. Saepe, exercitationem facilis? Error quod eveniet at nostrum asperiores delectus nihil deserunt ut alias? Sapiente.
      Animi quo accusantium excepturi atque quisquam, magnam quaerat temporibus, consequatur accusamus nisi praesentium libero rem officia ullam repudiandae perferendis sed dolor. Provident voluptatibus vero ratione repudiandae iusto fugiat quia distinctio!
      Veniam perferendis voluptatibus temporibus inventore, placeat quae harum iusto consequuntur? Possimus itaque perspiciatis rerum delectus nulla deserunt, ea illo laboriosam quidem officiis enim! Modi sed excepturi vel porro delectus repellat?
      Reprehenderit doloribus officiis nam voluptas incidunt, ex totam, voluptate rem laborum ipsa, atque fugiat. Suscipit, harum? Vero vitae est debitis, excepturi odio inventore voluptas nam nihil ut expedita repudiandae enim.
      Ut placeat id mollitia expedita at omnis velit veniam cum accusamus facere laboriosam ea cumque officia modi, delectus fugit rem dolores fuga magni veritatis eos explicabo animi ipsam. Consequuntur, tempore?
      Dolorum aperiam quibusdam corrupti amet voluptate animi deleniti itaque aliquam consequuntur? Repellendus, quibusdam maiores. Animi, explicabo delectus ad dolore, labore incidunt odit quod voluptate eveniet fugit obcaecati dicta laborum eius.
      Harum, qui voluptas repellat at dignissimos rem atque maiores excepturi dolores exercitationem, eum sed, assumenda dolorum. Consequuntur laudantium consectetur quisquam dolor distinctio eos eligendi magni debitis cum voluptas! Molestiae, veritatis?
      Repellendus rerum ipsa pariatur aperiam provident, necessitatibus quis illum expedita quibusdam earum magnam sapiente laudantium iusto ad ab harum labore esse molestias tempore sed animi vel nisi natus! Delectus, culpa.
      Quos cum a maxime harum eum, earum nesciunt atque sed assumenda quibusdam necessitatibus, explicabo illum ipsum odio aspernatur doloremque distinctio exercitationem maiores modi esse. Distinctio ullam at ea alias quaerat.
      Cupiditate, distinctio perferendis. Tempora quisquam asperiores explicabo officiis hic illo, enim maxime fuga perspiciatis, ipsum tenetur nostrum nulla, deserunt id distinctio! Iusto aspernatur tempora adipisci, culpa optio nam deleniti modi?
      Vel maxime accusamus architecto minima non distinctio veritatis! Minima soluta, labore repellat incidunt fugiat, sequi exercitationem temporibus animi nobis officia sapiente saepe quibusdam nihil quidem laborum porro dolore, eligendi dolores!
      Nesciunt quas voluptas harum dolorum molestias ex illo dolores! Quaerat, molestias nulla, iusto quod inventore quasi adipisci perspiciatis ad sapiente distinctio voluptatibus doloremque ducimus facilis nesciunt eveniet ipsa amet doloribus.
      Magnam rem maiores expedita inventore delectus pariatur possimus alias ab quas velit. Veniam cum cupiditate distinctio libero debitis atque sint, deleniti odio odit fugiat, pariatur inventore voluptatum necessitatibus. Dolorum, eveniet.
      Maiores cumque repellat officia quas accusamus dolor sapiente quod quis corrupti molestias aliquid reprehenderit eum consectetur laboriosam dolorum porro quos tempore, rerum magni dolores distinctio ex voluptatibus voluptatum. Architecto, consequatur.
      Iusto minus autem iste, maxime fugit quibusdam. Dicta suscipit qui amet porro ad numquam assumenda quas sit sunt, veritatis libero illo quam ullam enim cumque, consequuntur odio. Nobis, perferendis laboriosam.
    </div>
  );
};

export default StandardsList;
