import React, { useEffect, useState, useRef } from 'react';

import { Button } from '@newsela/angelou';
import { useStoreActions } from 'easy-peasy';
import { get, isEmpty, findLast, findIndex, sortBy } from 'lodash-es';
import Dropdown from 'mineral-ui/Dropdown';
import PropTypes from 'prop-types';

import Icon from '@client/common/components/Icon';
import { Image, Level } from '@client/common/schema';
import ValidationMessage from '@client/forms/components/ValidationMessage';
import { groupLevels } from '@client/utils/article-levels-selector';
import { formatGradeBand, sortGradeBands, formatLanguage } from '@client/utils/fields';
import { getFieldValidation, getCaption } from '@client/utils/form-validation';
import { getVariantIcon } from '@client/utils/styles';
import { NO_LEXILE } from '@shared/constants';

import { $root, $dropdown, $icon, $info, $contextTab, $fieldsWrapper, $bottomCaption, $menuItem } from './style';
import Prosemirror from '../ProsemirrorLeveler';

// We're calling this component LevelGroup because the 'Group' part will
// prevent Mineral-UI's FormField from wrapping the entire thing in a <label>,
// allowing us to get around the native label issues with multiple child buttons
// (when hovering or clicking any input, that behavior is transmitted to the first
// button in the formatting menu).
export default function LevelGroup ({ value, name, config, onChange, formData, variant, validatedContent }) {
  const availableLevels = (value && value.filter((level) => level.isActive)) || []; // Cast empty values to an array.
  const hasLevels = !isEmpty(value);
  // If we have optimistic data that means we have a request in progress
  const hasOptimisticData = availableLevels.some((item) => item.uid.match(/^_:/));
  // Switching between levels, 'selecting' a different level, will switch
  // what text is displayed in the Prosemirror input.
  const [selected, setSelected] = useState(get(availableLevels, '0.uid') || null);
  const selectedLevel = availableLevels.find((level) => level.uid === selected);
  const openModal = useStoreActions((actions) => actions.forms.modal.open);
  const selectedIndex = availableLevels.indexOf(selectedLevel);
  const [isChanging, setIsChanging] = useState(false);
  // Reference to the DOM element that opened the modal.
  // This reference is used to get the focus back when modal closes.
  const returnFocusRef = useRef(null);
  // group levels by language and order by grade band
  const groupedLevels = groupLevels(availableLevels);
  const currentLanguage = selectedLevel?.language;
  const indexInLanguage = currentLanguage ? findIndex(groupedLevels[currentLanguage], (level) => level.uid === selectedLevel.uid) : -1;
  // The 'level above' is the first level that is above the selected one
  // that contains text. When viewing levels, this is the fallback if a selected
  // level does not exist (e.g. in the article read page, etc).
  const levelAbove = indexInLanguage > 0
    ? findLast(groupedLevels[currentLanguage], (level) => {
      return level.text && level.text.length > 0;
    }, indexInLanguage - 1)
    : null;

  useEffect(() => {
    if (hasLevels) {
      // If no level is selected when opening the form, default to the highest one.
      if (!selected || !availableLevels.find((level) => level.uid === selected)) {
        const activeLevel = findLast(groupedLevels.LANG_EN || groupedLevels.LANG_ES);
        setSelected(activeLevel ? activeLevel.uid : null);
        // For the selected level, we want to show the error message.
        updateErrorMessage(selected);
      }
    }
  }, [formData && formData.id, availableLevels]);

  // Used to sort from highest to lowest in the dropdown.
  const sortedLevels = [...availableLevels].sort(sortGradeBands);

  const openLevelModal = (e) => {
    openModal({
      title: 'Create Level',
      config: Image.forms.levelSelect,
      data: {
        uid: formData.uid,
        captionLevels: availableLevels,
        contentType: formData.contentType
      },
      returnFocusRef,
      onSave: (uid, serverData, optimisticData) => {
        // Send the new level up to the form.
        onChange(
          { [name]: serverData.set.captionLevels },
          { [name]: optimisticData.captionLevels }
        );
      }
    });
  };
  const onProsemirrorInput = (serverData, optimisticData) => {
    // Note that the lexile will NOT update until it has returned from the server.
    const newData = {
      ...selectedLevel,
      ...optimisticData
    };
    const newLevels = [...availableLevels];

    newLevels.splice(selectedIndex, 1, newData);

    // Set a changing flag here that can be used to prevent other UI elements
    // from triggering more onChange requests before this one finishes.
    // In contrast with other onChange calls in this component: in this scenario
    // there is no optimistic data to detect if a request
    // is in progress so we must manually set a flag.
    // This is particularly useful for interactions in levels dropdown
    // to prevent the user from seeing and selecting data that is changing.
    setIsChanging(true);
    // Send the text updates to the form.
    onChange(
      { [name]: { uid: selectedLevel.uid, ...serverData } },
      { [name]: newLevels }
    ).finally(() => {
      // unset the changing flag here unblock any UI element
      setIsChanging(false);
    });
  };

  let selectedVariant, selectedErrorMessage;
  const uidFieldPaths = {};
  if (!isEmpty(validatedContent?.errors) || !isEmpty(validatedContent?.warnings)) {
    // The server sorts the levels by the uid.
    // Here we need to do the same to get the right field path for errors and warnings.
    const levelsSortedByUid = sortBy(availableLevels, (level) => parseInt(level.uid, 16));
    // Maps uids to field path.
    levelsSortedByUid.forEach((level, index) => {
      const fieldPath = `captionLevels.${index}`;
      const { errors, warnings } = getFieldValidation(validatedContent, { id: formData.id }, fieldPath, true);
      const { variant, caption } = getCaption(errors, warnings, { caption: null });
      uidFieldPaths[level.uid] = { fieldPath, variant, caption };
    });
  }
  // Get the variant for the dropdown button. Danger if there are errors in any level
  // or warning if there are warnings or null/undefined if there are no errors or warnings.
  const dropdownVariant = sortBy(uidFieldPaths, ['variant'])[0]?.variant;
  function updateErrorMessage (selectedUid) {
    selectedErrorMessage = uidFieldPaths[selectedUid]?.caption;
    selectedVariant = uidFieldPaths[selectedUid]?.variant;
  }
  // This is used to create the menu items for the dropdown.
  const dropdownMenu = sortedLevels.map((level) => {
    // variant describes if there are errors or warnings in the level.
    const variant = uidFieldPaths[level.uid]?.variant;
    // For the selected level, we want to show the error message.
    if (selected === level.uid) { updateErrorMessage(level.uid); }
    // An icon to substitute the original Mineral-UI variant icon.
    const iconStart = variant ? { iconStart: getVariantIcon(variant) } : null;
    // return an mineral-ui menu item for each level.
    return {
      css: $menuItem(variant, selected === level.uid),
      ...iconStart,
      text: `${formatGradeBand(level.gradeBand)} (${formatLanguage(level.language)})`,
      secondaryText: level.lexile || NO_LEXILE,
      onClick: () => {
        setSelected(level.uid);
        updateErrorMessage(level.uid);
      }
    };
  }).concat({
    text: 'Edit Levels',
    iconStart: <Icon icon={Level.icon} customCss={$icon} />,
    onClick: openLevelModal
  });

  const contextTab = hasLevels
    ? (
      <Dropdown data={dropdownMenu} disabled={hasOptimisticData || isChanging} css={$dropdown(dropdownVariant)}>
        <Button
          ref={returnFocusRef}
          disabled={hasOptimisticData || isChanging}
          /* AUTOGENERATED TODO: update angelou to new flavor.
            see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
            for migration guide. */
          legacy_flavor={Button.legacy_flavor.flat}
          legacy_size={Button.legacy_size.small}
          legacy_statusColor={Button.legacy_statusColor.black}
          role='button'
          type='button'
        >
          {selectedLevel ? `Grade Band: ${formatGradeBand(selectedLevel.gradeBand)} (${formatLanguage(selectedLevel.language)})` : 'Edit Levels'}
        </Button>
      </Dropdown>
      )
    : (
      <Button
        ref={returnFocusRef}
        /* AUTOGENERATED TODO: update angelou to new flavor.
          see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
          for migration guide. */
        legacy_flavor={Button.legacy_flavor.flat}
        legacy_size={Button.legacy_size.small}
        legacy_statusColor={Button.legacy_statusColor.black}
        role='button'
        type='button'
        onClick={openLevelModal}
      >Create Level
      </Button>
      );
  const prosemirrorConfig = {
    value: 'rawText',
    formats: config.formats,
    // Block interactions with the Prosemirror if there are no levels
    // or there is optimistic data.
    isDisabled: !hasLevels || hasOptimisticData,
    ...hasLevels && levelAbove && {
      button: {
        input: 'magic-button',
        value: () => levelAbove.rawText,
        description: 'Copy level above'
      },
      placeholder: levelAbove.text
    },
    contextTab
  };

  return (
    <div css={$root}>
      <div css={$fieldsWrapper}>
        <Prosemirror
          value={selectedLevel ? selectedLevel.rawText : null}
          name='text'
          config={prosemirrorConfig}
          onChange={onProsemirrorInput}
          formData={selectedLevel || {}}
          {...selectedVariant && { variant: selectedVariant }}
        />
        <div css={$contextTab(dropdownVariant)}>{contextTab}</div>
      </div>
      <div css={$bottomCaption}>
        <span css={$info}>
          {selectedLevel
            ? (<span>{formatLanguage(selectedLevel.language)}, {selectedLevel.lexile || NO_LEXILE}</span>)
            : (<span>No level selected</span>)}
        </span>
        <ValidationMessage message={selectedErrorMessage} variant={selectedVariant} />
      </div>
    </div>
  );
}

LevelGroup.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.array,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  /** Full form data */
  formData: PropTypes.object,
  variant: PropTypes.string,
  validatedContent: PropTypes.object
};

LevelGroup.displayName = 'LevelGroup';
