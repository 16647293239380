import React, { useState, useEffect } from 'react';

import Button from 'mineral-ui/Button';
import IconKeyboardArrowDown from 'mineral-ui-icons/IconKeyboardArrowDown';
import IconKeyboardArrowUp from 'mineral-ui-icons/IconKeyboardArrowUp';
import PropTypes from 'prop-types';

import { getHigherGrades } from '@client/utils/fields';

import { $root, $buttonWrapper, $button, $rootCompared } from './style';
import LexileWordCount from '../LexileWordCount';
import VocabLevel from '../VocabLevel';

export default function VocabSelector ({ formData, showVocabLevel, showLexile, state, statefulView, toggleLeveledText, leveledData }) {
  const [showVocabButtons, setShowVocabButtons] = useState(false);
  const [selectedGrades, setSelectedGrades] = useState([]);

  const toggleVocabLevelButtons = () => {
    setShowVocabButtons(!showVocabButtons);
    dispatchTransaction(selectedGrades, !showVocabButtons);
  };

  const dispatchTransaction = (gradeBands) => {
    const { tr } = state;

    setSelectedGrades(gradeBands);
    tr.setMeta('vocabLevelsTransaction', true);
    tr.setMeta('vocabLevelsGrades', gradeBands);
    statefulView.current.dispatch(tr);
  };

  useEffect(() => {
    // When switching forms (e.g. when switching between article levels),
    // reset the selected vocab grades to all grades higher than the current article level.
    if (formData) {
      setSelectedGrades(getHigherGrades(formData.gradeBand));
    }
    // Also, minimize the selector pane.
    setShowVocabButtons(false);
  }, [formData.uid]);

  const renderLeveledFooterData = () => {
    return showLexile ? (
      <LexileWordCount formData={leveledData} />
    ) : null;
  };

  const renderOriginalTextData = () => {
    return (
      <>

        {showLexile ? (
          <LexileWordCount formData={formData} />
        ) : null}
        {showVocabLevel ? (
          <div css={$buttonWrapper}>
            <Button
              css={$button}
              minimal
              type='button'
              iconEnd={showVocabButtons ? <IconKeyboardArrowUp /> : <IconKeyboardArrowDown />}
              onClick={toggleVocabLevelButtons}
            >Vocab Level
            </Button>
          </div>
        ) : null}
        {showVocabLevel ? (
          <VocabLevel
            selectedGrades={selectedGrades}
            showVocabButtons={showVocabButtons}
            dispatchTransaction={dispatchTransaction}
          />
        ) : null}
      </>
    );
  };

  const renderSingleView = () => {
    return (
      <>
        <div css={$root}>
          {showLexile ? (
            <LexileWordCount formData={formData} />
          ) : null}
          {showVocabLevel ? (
            <div css={$buttonWrapper}>
              <Button
                css={$button}
                minimal
                type='button'
                iconEnd={showVocabButtons ? <IconKeyboardArrowUp /> : <IconKeyboardArrowDown />}
                onClick={toggleVocabLevelButtons}
              >Vocab Level
              </Button>
            </div>
          ) : null}
        </div>
        {showVocabLevel ? (
          <VocabLevel
            selectedGrades={selectedGrades}
            showVocabButtons={showVocabButtons}
            dispatchTransaction={dispatchTransaction}
          />
        ) : null}
      </>
    );
  };

  const renderComparedView = () => {
    return (
      <>
        <div css={$rootCompared}>
          <div css={{
            flex: 1,
            display: 'flex',
            flexWrap: 'wrap',
            borderRight: '1px solid #C0C5CF',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '16px'

          }}
          >
            {renderOriginalTextData()}
          </div>

          <div css={{
            flex: 1,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            paddingTop: '16px'
          }}
          >
            {renderLeveledFooterData()}
          </div>

        </div>
      </>
    );
  };
  return toggleLeveledText ? renderComparedView() : renderSingleView();
}

VocabSelector.propTypes = {
  /** Indicates wether we are in toggled view or not */
  toggleLeveledText: PropTypes.bool,
  /** Full form data */
  formData: PropTypes.object,
  /** Value to show the vocabLevel component */
  showVocabLevel: PropTypes.bool,
  /** Value to show the lexile component */
  showLexile: PropTypes.bool,
  /** State of the view */
  state: PropTypes.object,
  /** State of prosemirror component */
  statefulView: PropTypes.object
};
