import { css } from '@emotion/core';

export const $footer = css`
  display: flex;
  justify-content: flex-end;
`;

export const $cancelButton = css`
  font-weight: 400;
  margin: 16px 0;
`;

export const $nextButton = css`
  font-weight: 400;
  margin: 16px;
`;
