import React from 'react';

import { Button } from '@newsela/angelou';

import { $footer, $cancelButton, $nextButton } from './style';
import { TABS } from '../StandardsSelector';

const Footer = ({ setModalActive, activeTab, categorizedStandards, setActiveTab, handleClose }) => {
  const isReviewTab = activeTab === TABS.REVIEW;
  const isApplyDisabled = isReviewTab && categorizedStandards.length === 0;

  return (
    <footer css={$footer}>
      <Button flavor='SECONDARY' onClick={() => handleClose()} __cssFor={{ root: $cancelButton }}>
        Cancel
      </Button>
      <Button
        __cssFor={{ root: $nextButton }}
        onClick={() => !isReviewTab && setActiveTab(TABS.REVIEW)}
        disabled={isApplyDisabled}
      >
        {isReviewTab ? 'Apply Standards' : 'Next: Review Standards'}
      </Button>
    </footer>
  );
};

export default Footer;
