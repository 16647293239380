import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Loading, Modal, useModal } from '@newsela/angelou';
import { useParams } from '@reach/router';

import { queries } from '@client/common/graph';
import * as toast from '@client/utils/toast';

import Divider from './Divider';
import Filters from './Filters';
import Footer from './Footer';
import Header from './Header';
import ReviewContainer from './ReviewContainer';
import StandardsList from './StandardsList';
import { $modalContentStyles, $modalBody } from './style';

export const TABS = {
  SEARCH: 'search',
  REVIEW: 'review',
};

const StandardsSelector = () => {
  const [activeTab, setActiveTab] = useState(TABS.SEARCH);
  const [selectedStandards, setSelectedStandards] = useState([]);

  const [modalProps, setModalActive, triggerRef] = useModal(false);
  const { contentID } = useParams();

  const { data, loading, error } = useQuery(queries.fullContent, {
    variables: { id: contentID },
  });

  const alreadyAppliedStandards = data?.content?.metadataStandards || [];
  const allStandards = [...alreadyAppliedStandards, ...selectedStandards];

  const categorizedStandards = allStandards.map((standard) => ({
    ...standard,
    status: alreadyAppliedStandards.some((s) => s.id === standard.id) ? 'applied' : 'selected',
  }));

  if (loading) return <Loading />;

  if (error) {
    toast.error('Could not load standards, please try again');
    return null;
  }

  const handleOpen = () => setModalActive(true);
  const handleClose = () => setModalActive(false);

  return (
    <>
      <Button onClick={handleOpen} ref={triggerRef}>
        Manage Standards
      </Button>
      <Modal {...modalProps} onBackgroundClick={handleClose} labelId='modal_title'>
        <div css={$modalContentStyles}>
          <Header activeTab={activeTab} setActiveTab={setActiveTab} handleClose={handleClose} />
          <Divider />
          <div css={$modalBody}>
            {activeTab === TABS.SEARCH ? (
              <>
                <Filters />
                <StandardsList setSelectedStandards={setSelectedStandards} activeTab={TABS.SEARCH} />
              </>
            ) : (
              <ReviewContainer />
            )}
          </div>
          <Divider />
          <Footer
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleClose={handleClose}
            categorizedStandards={categorizedStandards}
          />
        </div>
      </Modal>
    </>
  );
};

export default StandardsSelector;
