/* These utils, used by the Assessment, AssessmentLevel, and AssessmentQuestion
 * schemas, allow us to auto-generate different types of assessments when
 * creating new assessments. The rules are:
 *
 * Multiple Choice Quiz (MCQ):
 * - when created from an alexandria article, gets the number of levels
 *   the article has
 * - when created from any other type of content, gets one level
 * - in both cases, each level gets 4 questions, with 4 options each
 *
 * Write Prompt (WP):
 * - when created from any type of content, gets one level
 * - each level gets 1 question
 *
 * Power Word Activity (PWA):
 * - when created from an alexandria article, gets the number of levels
 *   the article has
 * - when created from any other type of content, gets one level (this is not
 *   a supported content team use case at this time)
 * - in both cases, each level gets 2x the number of questions that the article
 *   level has definitions for. e.g. if there are 3 power word definitions
 *   on the grade 10 article level, there will be 6 power word questions on the
 *   grade 10 assessment level (3 power word association and 3 power word example)
 */

import cuid from 'cuid';
import { isEmpty } from 'lodash';

// Note that we're importing directly from the individual type schemas,
// not from @client/common/schema. This is because this utils file will be
// imported by the Assessment, AssessmentLevel, and AssessmentQuestion schemas
// and we need to avoid circular dependencies.
import Content from '@client/common/schema/content';
import QuestionOption from '@client/common/schema/question-option';
import { titleCaseSpaced } from '@client/utils/cases';

import { DEFAULT_LANG, DEFAULT_GRADE, NONFICTION } from './constants';

// Assessment types:
export const ASSESSMENT_QUIZ = 'QUIZ';
export const ASSESSMENT_WRITE_PROMPT = 'WRITE_PROMPT';
export const ASSESSMENT_POWER_WORDS_ACTIVITY = 'POWER_WORDS_ACTIVITY';

// Question types:
export const QUESTION_MULTIPLE_CHOICE = 'MULTIPLE_CHOICE';
export const QUESTION_WRITE_PROMPT = 'WRITE_PROMPT';
export const QUESTION_POWER_WORD_ASSOCIATION = 'POWER_WORD_ASSOCIATION';
export const QUESTION_POWER_WORD_EXAMPLE = 'POWER_WORD_EXAMPLE';
export const QUESTION_TYPES = [
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_WRITE_PROMPT,
  QUESTION_POWER_WORD_ASSOCIATION,
  QUESTION_POWER_WORD_EXAMPLE,
];

/**
 * Get the shared / default fields for AssessmentQuestion. These are fields that
 * all questions have.
 * @param {string} [id]
 * @param {number} [position]
 * @returns {object} with { client, server }
 */
function getQuestionDefaults (id, position) {
  const uid = `_:${id}`;

  return {
    client: {
      id,
      uid,
      name: `Q${position}`,
      updatedAt: Date.now(),
      certicaId: null,
      question: null,
      rawQuestion: null,
      htmlQuestion: null,
      notes: null,
      rawNotes: null,
      genre: NONFICTION,
      tags: [],
      metadataTags: [],
      metadataStandards: [],
      __typename: 'AssessmentQuestion',
      position
    },
    server: {
      id,
      uid,
      position
    }
  };
}

/**
 * Create a multiple choice quiz question.
 * @param {object} questionDefaults
 * @returns {object} with { client, server }
 */
function createQuizQuestion (questionDefaults) {
  const options = [
    QuestionOption.defaults(cuid()),
    QuestionOption.defaults(cuid()),
    QuestionOption.defaults(cuid()),
    QuestionOption.defaults(cuid())
  ];

  return {
    client: {
      ...questionDefaults.client,
      questionType: QUESTION_MULTIPLE_CHOICE,
      options: options.map((option) => option.client)
    },
    server: {
      ...questionDefaults.server,
      questionType: QUESTION_MULTIPLE_CHOICE,
      options: options.map((option) => option.server)
    }
  };
}

/**
 * Create a write prompt question.
 * @param {object} questionDefaults
 * @returns {object} with { client, server }
 */
function createWritePromptQuestion (questionDefaults) {
  return {
    client: {
      ...questionDefaults.client,
      questionType: QUESTION_WRITE_PROMPT
    },
    server: {
      ...questionDefaults.server,
      questionType: QUESTION_WRITE_PROMPT
    }
  };
}

/**
 * Create a power word association or example question.
 * @param {object} questionDefaults
 * @param {string} questionType
 * @param {object} wordDefinition with { uid }
 * @returns {object} with { client, server }
 */
function createPowerWordQuestion (questionDefaults, questionType, wordDefinition) {
  const options = [
    QuestionOption.defaults(cuid()),
    QuestionOption.defaults(cuid())
  ];

  return {
    client: {
      ...questionDefaults.client,
      questionType,
      wordDefinition,
      options: options.map((option) => option.client)
    },
    server: {
      ...questionDefaults.server,
      questionType,
      wordDefinition,
      options: options.map((option) => option.server)
    }
  };
}

/**
 * Create an assessment question based on the question type.
 * @param {string} [id]
 @ @param {object} [data]
 * @returns {object} with { client, server }
 */
export function createAssessmentQuestion (id, data = {}) {
  // Generate an id if it hasn't been passed in.
  // eslint-disable-next-line no-param-reassign
  id = id || cuid();

  const questionType = data.questionType || QUESTION_MULTIPLE_CHOICE;
  const position = data.position || null;
  const questionDefaults = getQuestionDefaults(id, position);

  if (questionType === QUESTION_MULTIPLE_CHOICE) {
    return createQuizQuestion(questionDefaults);
  } else if (questionType === QUESTION_WRITE_PROMPT) {
    return createWritePromptQuestion(questionDefaults);
  } else if ([QUESTION_POWER_WORD_ASSOCIATION, QUESTION_POWER_WORD_EXAMPLE].includes(questionType)) {
    return createPowerWordQuestion(
      questionDefaults,
      questionType,
      data.wordDefinition
    );
  } else {
    // If none of these match, throw an error.
    throw new Error(`Cannot create AssessmentQuestion for type "${questionType}"`);
  }
}

/**
 * Get the shared / default fields for AssessmentLevel. These are fields that
 * all assessment levels have.
 * @param {string} [id]
 * @param {object} [data]
 * @returns {object} with { client, server }
 */
function getLevelDefaults (id, data = {}) {
  const uid = `_:${id}`;
  const language = data.language || DEFAULT_LANG;
  const gradeBand = data.gradeBand || DEFAULT_GRADE;

  return {
    client: {
      uid,
      gradeBand,
      language,
      isActive: true,
      __typename: 'AssessmentLevel',
    },
    server: {
      uid,
      gradeBand,
      language,
      isActive: true
    }
  };
}

/**
 * Create a multiple choice quiz level.
 * @param {object} levelDefaults
 * @returns {object} with { client, server }
 */
function createQuizLevel (levelDefaults) {
  const questions = [
    createAssessmentQuestion(cuid(), { questionType: QUESTION_MULTIPLE_CHOICE, position: 1 }),
    createAssessmentQuestion(cuid(), { questionType: QUESTION_MULTIPLE_CHOICE, position: 2 }),
    createAssessmentQuestion(cuid(), { questionType: QUESTION_MULTIPLE_CHOICE, position: 3 }),
    createAssessmentQuestion(cuid(), { questionType: QUESTION_MULTIPLE_CHOICE, position: 4 })
  ];

  return {
    client: {
      ...levelDefaults.client,
      questions: questions.map((question) => question.client)
    },
    server: {
      ...levelDefaults.server,
      questions: questions.map((question) => question.server)
    }
  };
}

/**
 * Create a write prompt level.
 * @param {object} levelDefaults
 * @returns {object} with { client, server }
 */
function createWritePromptLevel (levelDefaults) {
  const question = createAssessmentQuestion(cuid(), { questionType: QUESTION_WRITE_PROMPT, position: 1 });

  return {
    client: {
      ...levelDefaults.client,
      questions: [question.client]
    },
    server: {
      ...levelDefaults.server,
      questions: [question.server]
    }
  };
}

/**
 * Create a power word level.
 * @param {object} levelDefaults
 * @param {array} wordDefinitions
 * @returns {object} with { client, server }
 */
function createPowerWordLevel (levelDefaults, wordDefinitions) {
  const questions = (wordDefinitions || []).reduce((acc, definition, index) => {
    return [
      ...acc,
      createAssessmentQuestion(cuid(), {
        questionType: QUESTION_POWER_WORD_ASSOCIATION,
        wordDefinition: { uid: definition.uid },
        position: (index * 2) + 1
      }),
      createAssessmentQuestion(cuid(), {
        questionType: QUESTION_POWER_WORD_EXAMPLE,
        wordDefinition: { uid: definition.uid },
        position: (index * 2) + 2
      })
    ];
  }, []);

  return {
    client: {
      ...levelDefaults.client,
      questions: questions.map((question) => question.client)
    },
    server: {
      ...levelDefaults.server,
      questions: questions.map((question) => question.server)
    }
  };
}

/**
 * Create an assessment level based on the assessment type.
 * @param {string} [id]
 * @param {object} [data]
 * @param {object} [relatedData]
 * @returns {object} with { client, server }
 */
export function createAssessmentLevel (id, data = {}, relatedData = {}) {
  // eslint-disable-next-line no-param-reassign
  id = id || cuid();

  const levelDefaults = getLevelDefaults(id, data);
  const assessmentType = relatedData.assessmentType || ASSESSMENT_QUIZ;

  if (assessmentType === ASSESSMENT_QUIZ) {
    return createQuizLevel(levelDefaults);
  } else if (assessmentType === ASSESSMENT_WRITE_PROMPT) {
    return createWritePromptLevel(levelDefaults);
  } else if (assessmentType === ASSESSMENT_POWER_WORDS_ACTIVITY) {
    // Create a power word association and a power word example for each definition.
    return createPowerWordLevel(levelDefaults, relatedData.wordDefinitions);
  } else {
    // If none of these match, throw an error.
    throw new Error(`Cannot create AssessmentLevel for type "${assessmentType}"`);
  }
}

/**
 * When creating quizzes and power word activities from articles, it will create
 * assessment levels matching the gradebands and languages of the article levels.
 * When creating write prompt activities on articles, or when creating any
 * assessments on other types of content, it will create a single
 * (english, grade 12) level.
 * @param {string} assessmentType
 * @param {object} relatedData
 * @returns {array} of levels with { client, server }
 */
function createAssessmentLevels (assessmentType, relatedData) {
  const shouldGenerateMultipleLevels = !isEmpty(relatedData.articleLevels) &&
    [ASSESSMENT_QUIZ, ASSESSMENT_POWER_WORDS_ACTIVITY].includes(assessmentType);

  if (shouldGenerateMultipleLevels) {
    const levelsToGenerate = relatedData.articleLevels.filter((articleLevel) => {
      // Only generate assessment levels for ACTIVE article levels!
      return articleLevel.isActive;
    });

    return levelsToGenerate.map((articleLevel) => {
      // Pass through any power word definitions, so we can generate questions
      // for them.
      const wordDefinitions = articleLevel.wordDefinitions;

      return createAssessmentLevel(cuid(), {
        gradeBand: articleLevel.gradeBand,
        language: articleLevel.language
      }, { assessmentType, wordDefinitions });
    });
  } else {
    return [createAssessmentLevel(cuid(), {}, { assessmentType })];
  }
}

/**
 * Create an assessment based on the assessment type and parent data.
 * @param {string} [id]
 * @param {object} [data]
 * @param {object} [relatedData]
 * @returns {object} with { client, server }
 */
export function createAssessment (id, data = {}, relatedData = {}) {
  const contentDefaults = Content.defaults(id, data);
  const assessmentType = data.assessmentType || ASSESSMENT_QUIZ;
  const assessmentLevels = createAssessmentLevels(assessmentType, relatedData);

  return {
    client: {
      ...contentDefaults.client,
      levels: assessmentLevels.map((level) => level.client),
      __typename: 'Assessment',
      contentType: 'ASSESSMENT',
      name: titleCaseSpaced(assessmentType),
      assessmentType,
      taxonomyTags: [],

    },
    server: {
      ...contentDefaults.server,
      levels: assessmentLevels.map((level) => level.server),
      contentType: 'ASSESSMENT',
      assessmentType
    }
  };
}
