import React, { useMemo, useState, useLayoutEffect, useRef, useEffect, forwardRef } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Button } from '@newsela/angelou';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { isString, isEmpty, debounce, isUndefined, get } from 'lodash-es';
import PropTypes from 'prop-types';
import { Slice } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { useInView } from 'react-hook-inview';
import useDeepCompareEffect from 'use-deep-compare-effect';

import LoadingSpinner from '@client/common/components/LoadingSpinner';
import { queries } from '@client/common/graph';
import AttachedButton from '@client/forms/components/AttachedButton';
import FormattingMenu from '@client/forms/components/FormattingMenu';
import InlineImage from '@client/forms/components/InlineImage';
import VocabSelector from '@client/forms/components/VocabSelector';
import { TEXT_DEBOUNCE_TIME } from '@client/utils/constants';

import createOptions from './formats';
import { filterDoc, updatePlaceholder } from './formats/helpers';
import createReactNodeView from './node-views/createReactNodeView';
import { createInsertedContent } from './plugins/inline-image';
import { createPerParagraphLexilePlugin } from './plugins/per-paragraph-lexile';
import powerWordsPlugin from './plugins/power-words';
import { vocabLevelPlugin } from './plugins/vocab-level';
import {
  $root,
  $menuWrapper,
  $inputWrapper,
  $input,
  $attachedButton,
  $lexileInViewWrapper,
  $dualViewContainer,
  $leveledTextContainer,
  $comparedTextInput,
  $loadingStylesOverrides
} from './style';
import { transformPastedText, transformPastedHTML } from './utils/sanitize';
import useLeveler from './utils/useLeveler';

/**
 * Create a state from prosemirror json.
 * Filter the value to remove any nodes or marks that aren't supported in
 * our current field's config.formats.
 * @param {object} value
 * @param {object} options
 * @param {array} plugins
 * @returns {EditorState}
 */
function createState (value, options, plugins) {
  const parsedValue = filterDoc(
    value,
    Object.keys(options.schema.nodes),
    Object.keys(options.schema.marks)
  );

  return EditorState.create({
    schema: options.schema,
    doc: options.schema.nodeFromJSON(parsedValue),
    plugins: [...options.plugins, ...plugins]
  });
}

const ProsemirrorLeveler = forwardRef(({
  value,
  name,
  parent,
  config,
  onChange,
  formData,
  variant,
  lexileInView,
  refreshValue,
  validatedContent
}, ref) => {
  // We create a custom state specifically for the leveler since its behavior
  // is notably different from other menu items. Additionally, there are some
  // custom overrides that, if abstracted further, would make the code more challenging to understand and maintain.
  const { fetchLeveledArticle, leveledData, levelerStatus, setLevelerStatus } = useLeveler(formData, parent);

  const client = useApolloClient();
  // Convert empty values to a new prosemirror document.
  // eslint-disable-next-line no-param-reassign
  value = value || {
    type: 'doc',
    content: [{ type: 'paragraph' }]
  };

  const firstRender = useRef(true);
  const uid = useRef(formData.uid);
  const inputRef = useRef(null);
  const comparedTextRef = useRef(null);

  // Because of equality checks in Prosemirror, we must pass the
  // WHOLE view into the menu for the actions it triggers to have
  // transactional integrity.
  const statefulView = useRef(null);
  // However, referencing view.state in the menu does NOT update the buttons'
  // active status, so we must also pass in state by itself.
  // This `state` is NOT the same as view.state, and must not have transactions
  // done against it.
  const [state, setState] = useState(null);
  const portals = useRef([]);

  // When the ArticleLevelsSelector is sticky for a SingleArticle
  // we need to adjust the timing and placement of the sticky FormattingMenu:
  // adjusts the rootMargin of the scrollRef that determines when to stick
  // and the top padding of the FormattingMenu
  const customStick = config?.showLexile;
  const rootMargin = customStick ? '-246px 0px 0px 0px' : '0px';
  const [scrollRef, inView] = useInView({ rootMargin });

  // We store powerWords in the state so that they can be accessed
  // in the Drawer component.
  const powerWords = useStoreState((state) => state.powerWords);
  const vocabLevels = useStoreState((state) => state.vocabLevels);
  const setVocabLevels = useStoreActions((actions) => actions.setVocabLevels);
  const user = useStoreState((state) => state.user);

  // Determine whether we should be querying for vocab highlighting.
  const isVocabToggled = useRef(false);
  const [fetchVocabData] = useLazyQuery(queries.vocabWords, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (!isVocabToggled.current) { return; } // This prevents updating the highlighting after the user changing to another article level.
      const vocabWords = get(data, 'vocabWords', []);
      if (vocabWords) {
        setVocabLevels(vocabWords);
        const tr = statefulView.current.state.tr.setMeta('vocabLevels', vocabLevels);
        statefulView.current.dispatch(tr);
      }
    }
  });
  const vocabQueryVariables = useRef({
    variables: {
      uid: formData.uid,
      field: name,
      gradeBands: []
    }
  });

  // Generate options based on the config.
  const options = useMemo(() => {
    return createOptions(config.formats || [], config.isMultiline, user);
  }, [config.formats, config.isMultiline]);
  const isLevelerInput = options.menu.leveler && Object.keys(options.menu.leveler).length > 0;

  // Call onChange() in a debounced fashion when data changes.
  const onProsemirrorInput = debounce((doc) => {
    const serverChange = { [name]: doc.toJSON() };
    if (formData.__typename === 'ArticleLevel' && name === 'text') {
      serverChange.language = formData.language;
    }
    onChange(
      // Server change, e.g. 'title'
      serverChange,
      // Client change, e.g. 'rawTitle' (rich text) and 'title' (plaintext)
      { [config.value]: doc.toJSON(), [name]: doc.textContent }
    );

    if (isVocabToggled.current) {
      fetchVocabData(vocabQueryVariables.current);
    }
  }, TEXT_DEBOUNCE_TIME);

  // When we create the perParagraphLexile plugin, we need to pass in the
  // initial lexile scores.
  const perParagraphLexilePlugin = useMemo(() => {
    return createPerParagraphLexilePlugin(formData.lexilePerParagraph);
  }, [formData?.uid]);

  const updatePortals = (newValue) => {
    portals.current = newValue;
  };

  // Attached button
  const onButtonInput = (inputValue) => {
    let updatedValue = inputValue;
    if (isString(updatedValue)) {
      // Convert the value to JSON if it's actually a string
      updatedValue = {
        type: 'doc',
        content: [{
          type: 'paragraph',
          content: !isEmpty(updatedValue) ? [{
            type: 'text',
            text: updatedValue
          }] : [] // Prosemirror doesn't like empty paragraphs. https://discuss.prosemirror.net/t/empty-text-nodes-are-not-allowed-so-how-to-initialize-with-an-empty-paragraph/2361
        }]
      };
    }

    // Create a Prosemirror Slice from the updated value.
    const slice = Slice.fromJSON(options.schema, updatedValue);

    // Create a replace transaction to replace the current value with the
    // value from the button.
    const transaction = statefulView.current.state.tr.replace(
      0,
      statefulView.current.state.doc.content.size - 1,
      slice
    );
    // Dispatch the transaction.
    statefulView.current.dispatch(transaction);
  };

  // Update the uid ref when the form changes. This allows us to prevent other
  // kinds of updates (e.g. updating our decorators imperatively) when the
  // whole Prosemirror instance is being re-initialized. This has to happen in
  // a useEffect() so it's complete before useLayoutEffect() is called.
  useEffect(() => {
    uid.current = formData.uid;

    // When changing article levels, we turn the vocab highlighting off.
    isVocabToggled.current = false;
  }, [formData.uid]);

  const manageVocabLevelsTransaction = async (transaction, view) => {
    // Check the transaction to see if we need to fetch suggested VocabLevels.
    const gradeBands = transaction.getMeta('vocabLevelsGrades');
    isVocabToggled.current = !!gradeBands.length;
    if (isVocabToggled.current && formData.text) {
      vocabQueryVariables.current = {
        variables: {
          uid: formData.uid,
          field: name,
          gradeBands
        }
      };
      fetchVocabData(vocabQueryVariables.current);
    } else {
      setVocabLevels([]);
    }
  };

  // Re-initialize our Prosemirror instance when the form changes,
  // e.g. when switching drawers or switching caption levels inside the same drawer.
  useLayoutEffect(() => {
    // Reset the vocab words state.
    if (!isEmpty(vocabLevels)) {
      setVocabLevels([]);
    }
    // Create an initial Prosemirror state based on this field's data.
    const initialState = createState(value, options, [
      ...config.showLexile ? [perParagraphLexilePlugin] : [],
      ...config.showPowerWords ? [powerWordsPlugin] : [],
      ...config.showVocabLevel ? [vocabLevelPlugin] : []
    ]);
    // Create a Prosemirror view based on the initial state. This is where we
    // configure Prosemirror itself.
    const view = new EditorView(null, {

      state: initialState,
      editable: () => !config.isReadOnly && !config.isDisabled,
      // override the toDOM render of specific blocks, rendering React instead
      nodeViews: {
        image_block (node, view, getPos, decorations) {
          return createReactNodeView({
            node,
            view,
            getPos,
            decorations,
            Component: InlineImage,
            portals,
            updatePortals,
            formData,
            client,
            validatedContent
          });
        },
        powerWord_block (node, view, getPos, decorations) {
          const dom = document.createElement('span');
          dom.innerHTML = node.textContent;
          dom.className = `power-word power-word-${!node.attrs.definition ? 'un' : ''}defined`;
          dom.setAttribute('data-id', node.attrs.id);
          return { dom };
        }
      },
      transformPastedText,
      transformPastedHTML,
      // This method is called whenever we interact with the Prosemirror input,
      // e.g. clicking somewhere, typing a character, selecting and unselecting.
      // All transactions dispatched from format files go through this function.
      dispatchTransaction: async (transaction) => {
        // shouldUpdate allows us to cancel transactions based on the logic
        // in our plugins' manageTransaction methods. If our plugins return
        // false, we cancel the transaction and don't update the Prosemirror
        // state.
        let shouldUpdate = true;

        // Check the transaction to see if we need to create any inline content.
        const contentToInsert = transaction.getMeta('insertContent');
        if (contentToInsert) {
          await createInsertedContent(contentToInsert, client);
        }

        // TODO: loop through plugins manageTransactions functions
        // Right now, the only manageTransaction function that sets shouldUpdate
        // is the one in powerWordsPlugin. In the future, we may want to update
        // other plugins to set this variable as well.
        shouldUpdate = await powerWordsPlugin.custom.manageTransactions(transaction, view, formData, parent);

        const vocabLevelsTransaction = transaction.getMeta('vocabLevelsTransaction');
        if (vocabLevelsTransaction) {
          manageVocabLevelsTransaction(transaction, view);
        }

        if (!shouldUpdate) {
          // Don't update the Prosemirror state if one of our plugins explicitly
          // told us not to.
          return;
        }

        // Every keystroke and selection change creates a new transaction.
        // When this happens, we apply the transaction onto the current state
        // to generate a new state (similar to how Redux works), and then
        // update the view with the new state.
        const { state: newState, transactions } = view.state.applyTransaction(transaction);
        if (!view.isDestroyed) view.updateState(newState);

        // Only transactions where the actual document has changed trigger updates
        // to the form state and mutations to the server. Transactions where we
        // move the cursor or select some text do NOT trigger form updates.
        if (transactions.some((tr) => tr.docChanged)) {
          onProsemirrorInput(view.state.doc);
        }
        // When we do transactions, we need to update both of these!
        // Keep track of the view in our statefulView ref, so we can use it
        // in other places.
        statefulView.current = view;
        // Update the stateful (wow ok, I mean it uses useState()) 'state'
        // so components outside of Prosemirror (e.g. the Menu) re-render.
        if (!view.isDestroyed) setState(view.state);

        updatePlaceholder(view, config.placeholder);
      }
    });

    // Once our prosemirror view has been instantiated, save it to the statefulView
    // ref, and save the Prosemirror state to our component's local state so
    // components outside of Prosemirror (e.g. the Menu) re-render when they need to.
    statefulView.current = view;
    setState(view.state);

    // Add Prosemirror view to the DOM on first render.
    inputRef.current?.appendChild(view.dom);

    const leveledValue = leveledData.data?.article_levels[0]?.leveled_version?.raw_text;

    let initialStateLeveledText;
    // Creates a ProseMirror-like view with customized configurations tailored for the leveled text,
    if (leveledValue) {
      initialStateLeveledText = createState(leveledValue, options, []);
      const leveledTextView = new EditorView(null, {
        state: initialStateLeveledText,
        editable: () => false,
        // override the toDOM render of specific blocks, rendering React instead
        nodeViews: {
          image_block (node, view, getPos, decorations) {
            return createReactNodeView({
              node,
              view,
              getPos,
              decorations,
              Component: InlineImage,
              portals,
              updatePortals,
              formData,
              client,
              validatedContent
            });
          },
          powerWord_block (node, view, getPos, decorations) {
            const dom = document.createElement('span');
            dom.innerHTML = node.textContent;
            dom.className = `power-word power-word-${!node.attrs.definition ? 'un' : ''}defined`;
            dom.setAttribute('data-id', node.attrs.id);
            return { dom };
          }
        },
        transformPastedText,
        transformPastedHTML,
      });

      // Append the leveled data to the DOM only if it hasn't already been added, ensuring no duplicate content in the UI.
      if (comparedTextRef.current?.firstChild?.outerHTML !== leveledTextView.dom.outerHTML) {
        comparedTextRef.current?.appendChild(leveledTextView.dom);
      }
    }

    // If a ref was passed in, setting the prosemirror view to it
    // enable us to call view.focus() on the parent component
    // to set focus on the Prosemirror input.
    // eslint-disable-next-line no-param-reassign
    if (ref) ref.current = view;

    // Handle Prosemirror inline image drop events.
    // The transformations in the prosemirror nodes should
    // be applied by the node position.
    // https://discuss.prosemirror.net/t/helpers-for-transforming-nodes/622
    view.dom.addEventListener('drop', (e) => {
      const transferData = e.dataTransfer.getData('application/json');
      if (!transferData) return;

      const { nodePosition } = JSON.parse(e.dataTransfer.getData('application/json'));
      if (isUndefined(nodePosition)) return;

      const node = view.state.doc.nodeAt(nodePosition);
      const coords = { left: e.clientX, top: e.clientY };
      const dropPosition = view.posAtCoords(coords).pos; // Position at which to drop the image.
      const tr = view.state.tr;
      tr.delete(nodePosition, nodePosition + node.nodeSize);
      tr.replaceWith(dropPosition, dropPosition, node);
      view.dispatch(tr);
    });

    return () => {
      // Disable Power Words mode
      powerWordsPlugin.custom.onDestroy(config);
      // Destroy Prosemirror editor view before component unmounts.
      view.destroy();
    };
  }, [name, formData.id, formData.uid, config.isDisabled, refreshValue, validatedContent?.hasErrors, validatedContent?.hasWarnings, levelerStatus.toggleLeveledText, leveledData.data]);

  // When lexilePerParagraph changes, create a Prosemirror transaction that
  // triggers the decorators to update. We explicitly DON'T do this when the form
  // changes, because when that happens the ENTIRE Prosemirror instance gets
  // re-initialized (the decorators get reset by the plugin's init() method).
  // To prevent this from triggering when the form changes, we check against
  // the uid ref.
  useDeepCompareEffect(() => {
    if (statefulView.current && formData.uid === uid.current) {
      const tr = statefulView.current.state.tr.setMeta('lexile', formData.lexilePerParagraph);

      statefulView.current.dispatch(tr);
    }
  }, [formData.lexilePerParagraph ?? {}]);

  // When powerWords change (meaning the user has entered or left
  // 'powerWords' editorMode), create a Prosemirror transaction triggers the
  // decorators to update.
  useDeepCompareEffect(() => {
    // TODO: move this logic to the plugin file.
    if (statefulView.current) {
      const tr = statefulView.current.state.tr.setMeta('decoratePowerWords', powerWords);
      statefulView.current.dispatch(tr);
    }
  }, [powerWords]);

  // When vocabLevels change (meaning the user has entered or left
  // 'vocabLevels' editorMode), create a Prosemirror transaction triggers the
  // decorators to update.
  useDeepCompareEffect(() => {
    if (statefulView.current) {
      const tr = statefulView.current.state.tr.setMeta('vocabLevels', vocabLevels);
      statefulView.current.dispatch(tr);
    }
  }, [vocabLevels]);

  // When inView is instantiated, it's false. We want to wait until we've
  // scrolled to the prosemirror input before allowing the menu to float.
  useEffect(() => {
    if (firstRender.current && inView) {
      firstRender.current = false;
    }
  }, [inView]);

  const renderProsemirrorInput = () => {
    return (
      <div css={{
        position: 'relative',
        maxWidth: levelerStatus.toggleLeveledText && '580px',
        width: '100%'
      }}
      >
        <div className={`prose-mirror-input-${name}`} css={$input(config, variant, isLevelerInput)} ref={inputRef} />
        {config.button &&
          <div css={$attachedButton}>
            <AttachedButton
              config={config}
              onUpdate={onButtonInput}
              formData={formData}
            />
          </div>}
        {levelerStatus.toggleLeveledText
          ? (
            <Button
              __cssFor={{
                root: {
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  height: '28px',
                  margin: '16px',
                  color: 'rgb(29, 29, 29)',
                  backgroundColor: '#e8e9e9',
                  '&:hover': {
                    backgroundColor: 'rgb(247, 248, 248)'
                  }
                }
              }}
              onClick={() => setLevelerStatus((prevState) => {
                return {
                  ...prevState,
                  toggleLeveledText: false
                };
              })}
            >
              Keep original text
            </Button>
            )
          : null}
      </div>
    );
  };

  const renderLeveledText = () => {
    if (leveledData.loading) {
      return (
        <div css={$leveledTextContainer(leveledData.loading)}>
          <LoadingSpinner
            noLabel
            loadingContainerOverrides={$loadingStylesOverrides}
          />
        </div>
      );
    }

    return (
      <div css={$leveledTextContainer(leveledData.loading)}>
        <div className={`prose-mirror-input-${name}`} css={$comparedTextInput(config, variant)} ref={comparedTextRef} />
        <Button
          __cssFor={{
            root: {
              position: 'absolute',
              bottom: 0,
              right: 0,
              height: '28px',
              margin: '16px'
            }
          }}
          onClick={() => {
            const leveledValue = leveledData.data?.article_levels[0]?.leveled_version?.raw_text;
            onButtonInput(leveledValue);
            // Closing is done asynchronously to allow the ProseMirror editor enough time to update its state, ensuring we capture the latest changes.
            setTimeout(() => {
              setLevelerStatus((prevState) => {
                return {
                  ...prevState,
                  toggleLeveledText: false
                };
              });
            }, 800);
          }}
        >
          Replace with leveled text
        </Button>
      </div>
    );
  };

  const renderComparedTexts = () => {
    return (
      <div css={$dualViewContainer}>
        <div>
          <p>Current Text</p>
          {renderProsemirrorInput()}

        </div>
        <div>
          <p>Leveled Text</p>
          {renderLeveledText()}
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {portals.current.map((Portal, index) => <Portal key={index} />)}
        {(config.showLexile || config.showVocabLevel) && (
          <div css={$lexileInViewWrapper(config.isMultiline && !firstRender.current && !lexileInView)}>
            <VocabSelector
              formData={formData}
              leveledData={leveledData.data?.article_levels[0]?.leveled_version}
              showVocabLevel={config.showVocabLevel}
              showLexile={config.showLexile}
              state={state}
              statefulView={statefulView}
              toggleLeveledText={levelerStatus.toggleLeveledText}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div css={$root(levelerStatus.toggleLeveledText)}>
      {/* This div sits at the top, and allows us to know when we've scrolled
          past the top of the prosemirror input */}
      <div ref={scrollRef} />
      <div css={$menuWrapper((config.isMultiline && !firstRender.current && !inView), customStick)}>
        <FormattingMenu
          items={options.menu}
          statefulView={statefulView}
          state={state}
          levelerStatus={levelerStatus}
          leveledData={leveledData}
          fetchLeveledArticle={fetchLeveledArticle}
          setLevelerStatus={setLevelerStatus}
          formData={formData}
        />
      </div>
      <div css={$inputWrapper}>
        {levelerStatus.toggleLeveledText ? (
          renderComparedTexts()
        ) : (
          renderProsemirrorInput()
        )}
      </div>
      {renderFooter()}
    </div>
  );
});

ProsemirrorLeveler.propTypes = {
  /** Field value, from the form-level state */
  value: PropTypes.any,
  /** Field name, which is also the property the data will be saved to */
  name: PropTypes.string,
  /** Full configuration object */
  config: PropTypes.object,
  /** Function that updates the form state and persists data */
  onChange: PropTypes.func,
  /** Full form data */
  formData: PropTypes.object,
  parent: PropTypes.object,
  variant: PropTypes.string,
  lexileInView: PropTypes.bool,
  /** Passed in value that forces component to reinstantiate */
  refreshValue: PropTypes.any,
  validatedContent: PropTypes.object
};

ProsemirrorLeveler.displayName = 'ProsemirrorGroup';

export default ProsemirrorLeveler;
