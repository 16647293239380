import React from 'react';

import { $divider } from './style';

const Divider = () => {
  return (
    <div css={$divider} />
  );
};

export default Divider;
