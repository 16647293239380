import { constants, ArticleSVG } from '@newsela/angelou';
import cuid from 'cuid';
import deline from 'deline';
import gql from 'graphql-tag';
import { escapeRegExp } from 'lodash-es';
import smartquotes from 'smartquotes';

import rawSchema from '@client/schema';
import { transformArticlePublication, getArticlePublicationIsRequired } from '@client/utils/article-publications';
import { titleCaseSpaced } from '@client/utils/cases';
import { SINGLE_FIELD, LANG_EN, LANG_ES, ALL_SUPPORTED_ARTICLE_TYPES } from '@client/utils/constants';
import { enumOptions, transformSlug } from '@client/utils/fields';
import { filterArchivedLegacyWorkflows } from '@client/utils/legacy-workflows';
import { transformLiteProductGrant, getLiteProductGrantIsRequired } from '@client/utils/lite-product-grant';

import ArticleLevel from './article-level';
import ArticlePublication from './article-publication';
import Content from './content';
import ContentProvider from './content-provider';
import LiteProductGrant from './lite-product-grant';
import MetadataStandard from './metadataStandard';
import MetadataTag from './metadataTag';
import Stream from './stream';
import Tag from './tag';
import TaxonomyTag from './taxonomyTag';

// Article type and slug are shared between forms.
const articleTypeField = {
  input: 'select',
  name: 'articleType',
  label: 'Type',
  defaultValue: 'ARTICLE_NEWS',
  enum: 'ArticleType',
  options: ALL_SUPPORTED_ARTICLE_TYPES
};

const slugField = {
  input: 'text',
  name: 'slug',
  transform: (data = '') => transformSlug(data),
  maxLength: 50
};

const openSearch = {
  index: 'article',
  fields: ['title^10', 'name^7', 'slug^5', 'description^3'],
  sort: [
    {
      updated_at: {
        order: 'desc'
      }
    }
  ],
  // If the user included a query we disable the sort so we don't affect the ranking.
  disableSortOnQuery: true,
  _source: ['id', 'content_type', 'name', 'content_provider', 'article_type', 'status', 'updated_at', 'updated_by']
};

function filter (query) {
  const quoted = smartquotes(query);

  return {
    or: [
      // Authors get smart quotes, but slugs shouldn't have any quotes.
      { author: { alloftext: quoted } },
      { slug: { regexp: `/${escapeRegExp(query)}/i` } }
    ]
  };
}

function quickFilter (query) {
  return {
    ...query.length <= 3
      ? {
          // If the query is 3 or fewer characters long, do a less accurate filter.
          slug: { allofterms: query },
          rootFn: 'slug|allofterms',
          contentType: { eq: 'ARTICLE' },
          not: { visibility: { eq: -1 } }
        }
      : {
          // If the query is longer than three characters, we can do a more accurate
          // regexp filter.
          slug: { regexp: `/${escapeRegExp(query)}/i` },
          rootFn: 'slug|regexp',
          contentType: { eq: 'ARTICLE' },
          not: { visibility: { eq: -1 } }
        }
  };
}

/**
 * Map numerical grades to the grade bands specified in our maturity slider's
 * 'marks' config.
 * @param {number} val
 * @returns {number}
 */
function toBand (val) {
  if (val < 4) {
    return 1; // LE
  } else if (val < 6) {
    return 2; // UE
  } else if (val < 9) {
    return 3; // MS
  } else {
    return 4; // HS
  }
}

/**
 * Transform the low value from maturity range slider grade bands
 * into numerical grades.
 * @param {number} val grade band from the slider
 * @returns {number} actual numerical grade
 */
function fromBandLow (val) {
  switch (val) {
    case 1: return 2;
    case 2: return 4;
    case 3: return 6;
    case 4: return 9;
  }
}

/**
 * Transform the high value from maturity range slider grade bands
 * into numerical grades.
 * @param {number} val grade band from the slider
 * @returns {number} actual numerical grade
 */
function fromBandHigh (val) {
  switch (val) {
    case 1: return 3;
    case 2: return 5;
    case 3: return 8;
    case 4: return 12;
  }
}

/**
 * Transform values for our maturity range slider. This converts from the
 * grade numbers (saved in the db) to a simplified grade band that we display
 * in the slider (specified as 1-4 in the slider's 'marks' object).
 * defaults to [4, 12] if no value
 * @param {array} value
 * @returns {array} with numbers 1-4 to represent grade bands
 */
function formatMaturityRange (value) {
  const [low, high] = value || [4, 12];

  return [
    toBand(low),
    toBand(high)
  ];
}

/**
 * Transform values for our maturity range slider. This converts from the
 * grade bands in the slider to actual grade numbers we can send to the Monolith.
 * @param {array} value
 * @returns {array}
 */
function transformMaturityRange (value) {
  const [low, high] = value;

  return [
    fromBandLow(low),
    fromBandHigh(high)
  ];
}

const Article = {
  // Does not include headerImage: that's added in composedContent
  fullFragment: gql`
    fragment fullArticle on Article {
      articleType
      slug
      author
      contentWarning
      maturityRange
      quizOmissionReason
      headerImageRendition
      sourcePublishedAt
      sourceUrl
      articleLevels {
        ...fullArticleLevel
      }
      articlePublications {
        ...fullArticlePublication
      }
      liteProductGrants {
        ...fullLiteProductGrant
      }
      legacyWorkflowIds
      legacyWorkflows {
        id
        name
      }
    }
    ${ArticleLevel.fullFragment}
    ${ArticlePublication.fullFragment}
    ${LiteProductGrant.fullFragment}
  `,
  filter,
  quickFilter,
  statusSelector: true,
  articleTypeSelector: true,
  hasContentProviderFilter: true,
  defaults: (id, data) => {
    const contentDefaults = Content.defaults(id, data);
    // When creating new articles, they're created with an 'original' level.
    const firstArticleLevel = ArticleLevel.defaults(cuid(), { isOriginalLevel: true });
    const newArticlePublicationDefaultsToEnglish = ArticlePublication.defaults(null, { language: LANG_EN });

    return {
      client: {
        ...contentDefaults.client,
        name: 'news-article',
        __typename: 'Article',
        contentType: 'ARTICLE',
        articleType: 'ARTICLE_NEWS',
        slug: null,
        author: null,
        contentWarning: '',
        maturityRange: [4, 12],
        quizOmissionReason: null,
        headerImageRendition: 'HORIZONTAL',
        headerImage: null,
        sourcePublishedAt: null,
        sourceUrl: null,
        legacyWorkflowIds: [],
        metadataTags: [],
        taxonomyTags: [],
        metadataStandards: [],
        legacyWorkflows: [],
        articlePublications: [newArticlePublicationDefaultsToEnglish.client],
        articleLevels: [firstArticleLevel.client],
        liteProductGrants: []
      },
      server: {
        ...contentDefaults.server,
        contentType: 'ARTICLE',
        articlePublications: [newArticlePublicationDefaultsToEnglish.server],
        articleLevels: [firstArticleLevel.server],
        liteProductGrants: []
      }
    };
  },
  isContent: true,
  icon: ArticleSVG,
  typename: 'Alexandria Article',
  openInApp: true,
  app: {
    icon: ArticleSVG,
    color: constants.colors.ui.greyDark[700],
    accentColor: constants.colors.ui.grey[700],
    title: 'Articles',
    initialDrawer: {
      drawerType: SINGLE_FIELD,
      customDrawerTitle: 'Attachments',
      formKey: 'attachments'
    },
    href: '/articles',
    permissionForAdmin: true,
    permissionForStaff: true,
    permissionForContributor: true,
    columns: [
      {
        value: 'id',
        showColumn: false
      },
      {
        value: 'uid',
        showColumn: false
      },
      {
        label: 'Slug',
        value: 'name',
        isClickable: true
      },
      {
        label: 'Type',
        value: 'articleType',
        enum: 'ArticleType'
      },
      {
        label: 'Content Provider',
        value: 'contentProvider',
        getProp: 'name'
      },
      {
        value: 'status',
        enum: true
      },
      {
        label: 'Updated On',
        value: 'updatedAt',
        date: true
      },
      {
        label: 'Updated By',
        value: 'updatedBy',
        list: 'name'
      }
    ],
    order: { desc: 'updatedAt' },
    rawFilter: (id) => deline`var(func: eq(updatedByIds, ${id})) @filter(type(Event)) {
      ~events @filter(type(Content) and type(Article) and not eq(visibility, -1)) {
        v as uid
      }
    }`,
    openSearch
  },
  forms: {
    shared: [
      articleTypeField,
      slugField,
      {
        input: 'app-button',
        name: 'id',
        type: 'Article',
        label: 'Edit In Articles App'
      },
      Content.inputs.attached,
      Tag.inputs.tags,
      TaxonomyTag.inputs.taxonomyTags,
      MetadataTag.inputs.metadataTags,
      MetadataStandard.inputs.metadataStandards,
      Stream.inputs.subjectProductStreams,
      Stream.inputs.customStreams,
      Content.inputs.notes,
      Content.inputs.parentsList,
      Content.inputs.id,
    ],
    app: [
      { tab: 'Details', layout: 'two-column' },
      { section: 'General' },
      {
        input: 'article-loader',
        // The name doesn't map to a real field, as this input doesn't update
        // the form as normal. Instead, it does an API call directly to the server
        // and triggers the whole form to reload with the updated data.
        name: 'articleLoader',
        label: 'Load Article'
      },
      articleTypeField,
      slugField,
      {
        input: 'text',
        name: 'author'
      }, {
        input: 'editor',
        name: 'headerImage',
        editorType: 'Image'
      }, {
        input: 'textarea',
        name: 'contentWarning',
        caption: 'To make this warning visible, please add the sensitive-content tag to the article before publishing.',
        button: {
          input: 'magic-button',
          value: () => 'The following article contains sensitive content that might be upsetting for some of our readers. You know best which content works for your classroom.',
          description: 'Add generic warning'
        }
      }, {
        input: 'slider',
        name: 'maturityRange',
        isRange: true,
        minValue: 1,
        maxValue: 4,
        hasSteps: false, // Only allow selecting a discrete mark.
        marks: {
          1: 'LE',
          2: 'UE',
          3: 'MS',
          4: 'HS'
        },
        // Transform the values before displaying them in the slider.
        format: formatMaturityRange,
        // Transform the values before saving them.
        transform: transformMaturityRange
      }, {
        input: 'radio',
        name: 'quizOmissionReason',
        isInline: false,
        caption: 'If this article will not have a quiz when it is published, please select a reason',
        options: [
        // 'None' is selected by default.
          { label: 'None', value: '' },
          // The other options come after the 'None' option.
          ...rawSchema.enums.QuizOmissionReason.map((reason) => ({
            label: titleCaseSpaced(reason),
            value: reason
          }))
        ]
      },
      {
        input: 'select',
        name: 'legacyWorkflows',
        label: 'Wire Workflow',
        validationName: 'legacyWorkflowIds',
        isAsync: true,
        isMulti: true,
        isRelation: true,
        preloadOptions: true,
        type: 'LegacyWorkflow',
        query: 'articleWorkflows',
        mapping: {
          label: 'name',
          value: 'id',
        },
        optionsFilter: filterArchivedLegacyWorkflows
      },
      { section: 'Original Article' },
      {
        input: 'datepicker',
        name: 'sourcePublishedAt',
        label: 'Original Publish Date',
        caption: 'Date and time when original article was published (in Eastern Time)'
      }, {
        input: 'text',
        type: 'url',
        name: 'sourceUrl',
        label: 'Original URL',
        caption: 'URL for the original article'
      },
      { section: 'Leveling' },
      {
        input: 'article-levels',
        name: 'articleLevels',
        label: 'Article Levels'
      },
      {
        section: 'Publish dates',
        description: `Select the date and time (in Eastern Time) for when
          the article should appear on the live site for each article's language.
          Levels for each language must be selected first before choosing a publish date.`
      },
      {
        input: 'datepicker',
        name: 'articlePublications',
        label: 'Publish date for English',
        language: LANG_EN,
        validationName: `articlePublications.${LANG_EN}`,
        placeholder: 'Publish date is unavailable until an English level is added',
        required: getArticlePublicationIsRequired,
        transform: transformArticlePublication
      },
      {
        input: 'datepicker',
        name: 'articlePublications',
        label: 'Publish date for Spanish',
        language: LANG_ES,
        validationName: `articlePublications.${LANG_ES}`,
        placeholder: 'Publish date is unavailable until a Spanish level is added',
        required: getArticlePublicationIsRequired,
        transform: transformArticlePublication
      },
      {
        section: 'Lite Product Membership',
        description: `Select the date and time (in Eastern Time) for when
          the article should be included in the Newsela Lite product.`
      },
      {
        input: 'datepicker',
        name: 'liteProductGrants',
        label: 'Visible After',
        validationName: 'liteProductGrants.dateStarts',
        required: getLiteProductGrantIsRequired,
        transform: transformLiteProductGrant
      },
      {
        input: 'datepicker',
        name: 'liteProductGrants',
        label: 'Expires After',
        validationName: 'liteProductGrants.dateEnds',
        required: getLiteProductGrantIsRequired,
        transform: transformLiteProductGrant
      },
      { section: 'Metadata' },
      {
        ...ContentProvider.inputs.contentProvider,
        required: true
      },
      Tag.inputs.tags,
      TaxonomyTag.inputs.taxonomyTags,
      MetadataTag.inputs.metadataTags,
      MetadataStandard.inputs.metadataStandards,
      {
        input: 'new-standard-selector',
        type: 'customFormField',
      },
      Stream.inputs.subjectProductStreams,
      Stream.inputs.customStreams,
      Content.inputs.notes,
      // Content.inputs.parentsList,
      Content.inputs.id,
      { tab: 'Content', layout: 'wide', hasNestedFields: true },
      {
        input: 'article-levels-selector',
        name: 'articleLevels',
        showLabel: false
      },
      { tab: 'Revisions', layout: 'wide' },
      {
        input: 'revision-history',
        name: 'comparison-context',
        options: [
          { label: 'Levels', value: 'levels' },
          { label: 'Versions', value: 'versions' }
        ],
        showLabel: false,
        isInline: false,
        defaultValue: 'levels'
      }
    ],
    levelSelect: [{
      input: 'grade-band-level-select',
      name: 'articleLevels',
      hasMultigradeBands: false,
      gradeBands: enumOptions(rawSchema.enums.GradeBand, 'GradeBand'),
      languages: enumOptions(rawSchema.enums.Language, 'Language')
    }],
    attachments: [
      {
        ...Content.inputs.attached,
        editorTypes: [
          ...Content.inputs.attached.editorTypes,
          {
            type: 'ExternalLink',
            title: 'Formative',
          },
        ],
      },
    ],
    powerWords: [{ input: 'power-word-list' }]
  }
};

export const LegacyWorkflow = {
  // When fetching the legacy workflows, we never filter them.
  search: () => {
    return {};
  },
  defaults: (id, data) => ({
    client: {
      id,
      name: data.newLabel || null,
      __typename: 'LegacyWorkflow'
    },
    server: { id }
  })
};

export default Article;
